
import CRMWarehouses from './screens/crm/warehouses';
import CRMOrders from './screens/crm/orders';
import CRMDocumentsGrid from './screens/crm/documents_grid';
import CRMItems from './screens/crm/items';
import CRMStorageItems from './screens/crm/storage_items';
import CRMDocuments from './screens/crm/documents';
import CRMPhysicalItems from './screens/crm/physical_items';


//import ReservationsDashboard from './screens/reservations/ViewReservations';
import PropertyViewer from './screens/properties/viewproperties';
import RentalsUnitedCMManager from './screens/services/rentals_untied_cm_manager';

import Workpackages from './screens/tasks/projects/workpackages';
import WorkPakageHub from './screens/tasks/projects/work_packages/view_hub';
import ManageProjects from './screens/tasks/projects/manageprojects';


import ZReport from './screens/statistics/zreport';
import PaceRep from './screens/statistics/pacerep';
import SalesRep from './screens/statistics/salesrep';
import AllSalesReport from './screens/statistics/allsales';

import CleaningSchedule from './screens/schedules/cleaning';
import CheckoutSchedule from './screens/schedules/checkouts';
import CleaningDefaultTypePriceEditor from './screens/schedules/default_type_price_editor';
import CleaningCostReport from './screens/statistics/cleaningReport';

import THistoryViewer from './screens/Logging/thistoryviewer';
import AppLog from './screens/applogs.jsx';

//import UserManagement from './screens/hr/usersmanagement';
//import UserAccessControllManagement from './screens/hr/useraccesscontroll';

import MonitoringView from './screens/monitoring/view';
//import PropertiesList from './screens/propertieslist';
//import SyncProperties from './screens/services/syncproperties';
import CalendarTable from './screens/reservations/calendarview';

import Home from './screens/backup_home';
import Typography from '@mui/material/Typography';

import InvoicesGrid from './screens/accountant/invoices/invoices_grid';
import CRMPartners from './screens/crm/partners';
import FileGrid from './screens/file_share/file_grid';
import AccountantParners from './screens/accountant/partners';
import InvoiceDialog from './screens/accountant/invoices/create_new';
import { sendEvent } from './common/gtag';
import Workers from './screens/tasks/projects/workers';

const modules = {
  1: { name: 'Home', component: Home },
  11: { name: 'Sales Rep', component: SalesRep },
  12: { name: 'Pace Rep', component: PaceRep },
  13: { name: 'Z Report', component: ZReport },
  14: { name: 'All Sales Report', component: AllSalesReport },
  15: { name: 'Cleaning Cost Report', component: CleaningCostReport },
  101: { name: 'App Log', component: AppLog },
  201: { name: 'Cleaning Schedule', component: CleaningSchedule },
  202: { name: 'Checkout Schedule', component: CheckoutSchedule },
  203: { name: 'Cleaning Default Type Price Editor', component: CleaningDefaultTypePriceEditor },
  //301: { name: 'Properties List', component: PropertiesList },
  401: { name: 'Property Viewer', component: PropertyViewer },
  //501: { name: 'Reservations Dashboard', component: ReservationsDashboard },
  1091: { name: 'THistory Viewer', component: THistoryViewer, orgargs: { qry: 'staysearch' } },
  1092: { name: 'THistory Viewer', component: THistoryViewer, orgargs: { qry: 'reaservationsearch' } },
  1093: { name: 'THistory Viewer', component: THistoryViewer, orgargs: { qry: 'propertiesprices' } },
  //2001: { name: 'Manage Projects', component: ManageProjects },
  3001: { name: 'CRM Items', component: CRMItems },
  3002: { name: 'CRM Storage Items', component: CRMStorageItems },
  3003: { name: 'CRM Documents', component: CRMDocuments },
  3004: { name: 'CRM Physical Items', component: CRMPhysicalItems },
  3005: { name: 'CRM Warehouses', component: CRMWarehouses },
  3006: { name: 'CRM Orders', component: CRMOrders },
  3007: { name: 'CRM Documents Grid', component: CRMDocumentsGrid },
  3008: { name: 'CRM Partners', component: CRMPartners },
  4001: { name: 'Calendar Table', component: CalendarTable },
  5001: { name: 'File Grid', component: FileGrid },
  6001: { name: 'Work Packages', component: Workpackages },
  6002: { name: 'Work Package Hub', component: WorkPakageHub },
  6003: { name: 'Workers', component: Workers },
  6004: { name: 'Projects', component: ManageProjects },
  7001: { name: 'AccountantPartners', component: AccountantParners },
  7002: { name: 'Invoices Grid', component: InvoicesGrid },
  7003: { name: 'Invoice Dialog', component: InvoiceDialog },
  9001: { name: 'Monitoring View', component: MonitoringView },
  //9002: { name: 'User Access Controll Management', component: UserAccessControllManagement },
  //9101: { name: 'Sync Properties', component: SyncProperties },
  9102: { name: 'Rentals United CM Manager', component: RentalsUnitedCMManager },
};

export function getModuleNameById(id) {
  return modules[id]?.name || 'Unknown';
}

export function renderModule(selectedMenuItem, args = {}) {
  let qargs = args;
  const foundModule = modules[selectedMenuItem];
  if (foundModule && foundModule.orgargs) {
    qargs = { ...qargs, org: { ...foundModule.orgargs } };
  }
  sendEvent({ category: 'Menu', action: 'Selected', label: foundModule ? foundModule.name : selectedMenuItem });
  const ComponentToRender = foundModule ? foundModule.component : <Typography color='white'>Unhandled menu item {selectedMenuItem}</Typography>;
  return <ComponentToRender args={qargs} />;
}
