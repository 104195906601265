import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { TableGrid } from '../../common/TableGrid';
import SelectFieldDialog from '../../Dialogs/SelectFieldDialog';
import { useSnackbar } from '../../common/error_provider';

export default function MonitoringGeneralInfo({ visible, device }) {
  const [deviceInfo, setDeviceInfo] = useState({});
  const [agents, setAgents] = useState([]);
  const [showNewAgent, setShowNewAgent] = useState(false);
  const { showError, showInfo } = useSnackbar();

  const fetchGeneralInfo = async () => {
    setDeviceInfo(device);
  };

  const fetchAgents = async () => {
    if (!device?.id) return;
    try {
      const response = await axios.get(`http://localhost:3001/LeviathanMonitoring/DeviceAgents/${device.id}`);
      setAgents(response.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchGeneralInfo();
      fetchAgents();
    }
  }, [visible, device]);

  const handleNewAgent = (item) => {
    axios.post(`/LeviathanMonitoring/DeviceAgents/${device.id}`, { agent_id: item.agent_id })
      .then((res) => {
        if (res.status === 200) {
          showInfo('Agent added successfully');
          setAgents((prev) => [...prev, res.data]);
          setShowNewAgent(false);
        }
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          showError('Agent already exists');
        }
      });

  }

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Device Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>ID:</strong> {deviceInfo.id}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Device Name:</strong> {deviceInfo.device_name || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Device IPv4:</strong> {deviceInfo.device_ip4}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Hostname:</strong> {deviceInfo.hostname || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Last Online State:</strong> {deviceInfo.last_online_state || "N/A"}
            </Typography>
          </Grid>
        </Grid>

        {/* Agent Information */}
        <Box mt={4} p={2} border={1} borderColor="grey.300" borderRadius={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom>
              Agent Information
            </Typography>
            <IconButton color="primary" onClick={() => setShowNewAgent(true)}>
              <AddIcon />
            </IconButton>
          </Box>

          {/* List of Agents */}
          <TableGrid enableOuterShell={false} loading={false} rowsPerPage={10} columns={['ID', 'Name', 'Enabled']}>
            {agents.map((agent) => (
              <TableRow key={agent.device_agent_id}>
                <TableCell>{agent.agent_id}</TableCell>
                <TableCell>{agent.agent_name}</TableCell>
                <TableCell>{agent.device_agent_enabled ? "✅ Yes" : "❌ No"}</TableCell>
              </TableRow>
            ))}
          </TableGrid>
        </Box>
      </Paper>
      <SelectFieldDialog
        open={showNewAgent}
        onClose={() => setShowNewAgent(false)}
        title="Select Agent"
        keyField='agent_id'
        valueField='agent_name'
        url='/LeviathanMonitoring/Agents'
        onSave={handleNewAgent}
      />
    </Box>
  );
}

