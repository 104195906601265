import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  Grid, Card, CardContent, CardActionArea, Menu, MenuItem, LinearProgress
} from '@mui/material';
import {
  Add as AddIcon, Close as CloseIcon, CheckCircle as CheckCircleIcon,
  LocalShipping as LocalShippingIcon, Inventory2 as Inventory2Icon,
  MoveToInbox as MoveToInboxIcon, AssignmentTurnedIn as AssignmentTurnedInIcon,
  ShoppingCart as ShoppingCartIcon, Feed as FeedIcon, MoreVert, EventNote, Person, Warehouse
} from '@mui/icons-material';
import axios from 'axios';
import mqtt from 'mqtt';
import CRMWarehousesDialog from './pick_warehouse';
import ItemsTable from './documents/items_table';
import CRMCreateNewDocument from './documents/create_new_document';
import { orangeColor } from '../../css/common_sx';
import { getStatusIcon } from './document_status_icon';
export default function CRMDocuments() {
  const [documents, setDocuments] = useState([]);
  const [documentItems, setDocumentItems] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingItems, setLoadingItems] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showCreateDocument, setShowCreateDocument] = useState(false);
  const [showSelectWarehouse, setShowSelectWarehouse] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const mqttChannel = 'device/1678671';
  const [client, setClient] = useState(null);
  const [mqttConnectionStatus, setMqttConnectionStatus] = useState('Disconnected');
  const [changedItem, setChangedItem] = useState(null);

  useEffect(() => {
    fetchDocuments();

    const initMqttConnection = () => {
      const mqttClient = mqtt.connect('ws://localhost:8083/mqtt');

      mqttClient.on('connect', () => {
        setMqttConnectionStatus('Connected');
        mqttClient.subscribe(mqttChannel);
      });

      mqttClient.on('error', (err) => {
        setMqttConnectionStatus('Connection Error');
        mqttClient.end();
      });

      mqttClient.on('reconnect', () => {
        setMqttConnectionStatus('Reconnecting...');
      });

      mqttClient.on('offline', () => {
        setMqttConnectionStatus('Disconnected');
      });

      mqttClient.on('message', (topic, message) => {
        const { id } = JSON.parse(message.toString());
        setChangedItem(id);
        setDocumentItems((items) =>
          items.map((item) => {
            if (item.id === id && parseInt(item.item_qty) > 0) {
              return { ...item, item_qty: (parseInt(item.item_qty) - 1).toString() };
            }
            return item;
          })
        );
        setTimeout(() => setChangedItem(null), 500);
      });

      setClient(mqttClient);

      return mqttClient;
    };

    const mqttClient = initMqttConnection();

    return () => {
      mqttClient.end();
    };
  }, []);

  const fetchDocuments = (reference) => {
    setLoading(true);
    axios.get(`/Office/CRM/Documents${reference ? `/byref/${reference}` : ''}`)
      .then((response) => {
        setDocuments(response.data);
        setLoading(false);
      })
      .catch(() => {
        setError('Error fetching documents');
        setOpenSnackbar(true);
        setLoading(false);
      });
  };

  const fetchDocumentItems = (documentId) => {
    setLoadingItems(true);
    axios.get(`/Office/CRM/DocumentItems/show_to_zero/${documentId}`)
      .then((response) => {
        const sortedItems = response.data.sort((a, b) =>
          parseInt(a.item_qty) === 0 ? 1 : parseInt(b.item_qty) === 0 ? -1 : parseInt(b.item_qty) - parseInt(a.item_qty)
        );
        setDocumentItems(sortedItems);
        setLoadingItems(false);
      })
      .catch(() => {
        setError('Error fetching document items');
        setOpenSnackbar(true);
        setLoadingItems(false);
      });
  };

  useEffect(() => {
    selectedWarehouse ? fetchDocuments(selectedWarehouse.id) : fetchDocuments();
  }, [selectedWarehouse]);

  const handleDocumentPopup = (item) => {
    setSelectedDocument(item);
    fetchDocumentItems(item.id);
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);
  const handleSelectWarehouse = (item) => {
    setShowSelectWarehouse(false);
    setSelectedWarehouse(item || null);
  };



  const filteredDocuments = documents.filter(doc =>
    ['document_no', 'document_type_name', 'source_warehouse_name',
      'destination_warehouse_name', 'created_by', 'status_name']
      .some((key) => (doc[key] || '').toLowerCase().includes(globalFilter.toLowerCase()))
  );

  const getStatusText = (status) => status === 10 ? 'Active' : 'Inactive';
  const getProgressValue = (status) => status === 0 ? 0 : status >= 1 && status < 10 ? 50 : status === 10 ? 100 : 0;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" flex={1}>
      {/* Header Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={2} borderRadius={2} boxShadow={3} bgcolor={orangeColor}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setShowSelectWarehouse(true)}>
          Reference Warehouse
        </Button>
        {selectedWarehouse && (
          <>
            <Typography variant="body2">{selectedWarehouse.name}</Typography>
            <IconButton onClick={() => setSelectedWarehouse(null)}><CloseIcon /></IconButton>
          </>
        )}
        <TextField
          label={`Search (MQTT: ${mqttConnectionStatus} on ${mqttChannel})`}
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          fullWidth
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 2 }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setShowCreateDocument(true)}>
          Create New Document
        </Button>
      </Box>

      {/* Document List and Details Section */}
      <Box display="flex" width="100%" height="100%">
        {/* Document List */}
        <Box flex={1} overflow="auto" pr={2}>
          {loading ? (
            Array.from({ length: 5 }).map((_, i) => (
              <Skeleton key={i} variant="rectangular" height={100} animation="wave" mb={2} />
            ))
          ) : (
            <Grid container spacing={2}>
              {filteredDocuments.map((doc) => (
                <Grid item xs={12} sm={6} key={doc.id}>
                  <Card onClick={() => handleDocumentPopup(doc)} sx={{ bgcolor: doc.id === changedItem ? 'lightgreen' : 'white', transition: 'background-color 0.5s' }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6">Document No: {doc.document_no || 'N/A'}</Typography>
                        <Typography variant="body2" color="textSecondary">{doc.document_type_name}</Typography>
                        <Typography variant="body2" color="textSecondary">Created by: {doc.created_by}</Typography>
                        <Typography variant="body2" color="textSecondary">{doc.source_warehouse_name} → {doc.destination_warehouse_name}</Typography>
                        <Box mt={2}>{getStatusIcon(doc.status)} {doc.status_name}</Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {/* Document Details */}
        {selectedDocument ? (
          <Box flex={2} p={2} borderLeft="1px solid #ddd" ml={2} overflow="auto">
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="h6" color={orangeColor}>Document Details</Typography>
              <IconButton onClick={() => setSelectedDocument(null)}><CloseIcon /></IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem disabled={true} onClick={handleMenuClose}>Delete Document</MenuItem>
                <MenuItem disabled={selectedDocument.status === 0} onClick={handleMenuClose}>Edit Items</MenuItem>
                <MenuItem disabled={selectedDocument.status !== 0} onClick={handleMenuClose}>Reopen Document</MenuItem>
                <MenuItem disabled={true} onClick={handleMenuClose}>Check Log</MenuItem>
              </Menu>
            </Box>

            <Box position="relative" p={2} borderRadius={2} border={1} borderColor={orangeColor} bgcolor="#f9f9f9">
              <IconButton aria-label="more" onClick={handleMenuClick} sx={{ position: 'absolute', top: 8, right: 8 }}>
                <MoreVert />
              </IconButton>

              <Box display="flex" alignItems="center" mb={1}>
                <EventNote color="primary" />
                <Typography variant="h6" ml={1}><strong>Document No:</strong> {selectedDocument.document_no}</Typography>
              </Box>

              <Box display="flex" alignItems="center" mb={1}>
                <Person color="primary" />
                <Typography variant="body1" ml={1}><strong>Created By:</strong> {selectedDocument.created_by} <strong>Created At:</strong> {new Date(selectedDocument.created_at).toLocaleString()}</Typography>
              </Box>

              <Box display="flex" alignItems="center" mb={1}>
                <Warehouse color="primary" />
                <Typography variant="body1" ml={1}><strong>Source Warehouse:</strong> {selectedDocument.source_warehouse_name}</Typography>
              </Box>

              <Box display="flex" alignItems="center" mb={1}>
                <Warehouse color="primary" />
                <Typography variant="body1" ml={1}><strong>Destination Warehouse:</strong> {selectedDocument.destination_warehouse_name}</Typography>
              </Box>

              <Box display="flex" alignItems="center" mb={2}>
                {getStatusIcon(selectedDocument.status)}
                <Typography variant="body1" ml={1}><strong>Status:</strong> {selectedDocument.status_name}</Typography>
              </Box>

              {/* Status Progress */}
              <Box mt={3}>
                <Typography variant="body2" color="textSecondary" gutterBottom>Delivery Progress</Typography>
                <LinearProgress variant="determinate" value={getProgressValue(selectedDocument.status)} sx={{ height: 10, borderRadius: 5 }} />
                <Typography variant="body2" color="textSecondary" align="right" mt={1}>{getStatusText(selectedDocument.status)} ({getProgressValue(selectedDocument.status)}%)</Typography>
              </Box>
            </Box>

            {/* Items List */}
            <Typography variant="h6" mt={2}>Items</Typography>
            {loadingItems ? (
              <Skeleton variant="rectangular" height={100} mb={2} />
            ) : (
              <Grid container spacing={2}>
                {documentItems.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Card sx={{ bgcolor: item.id === changedItem ? 'lightblue' : 'white', transition: 'background-color 0.5s' }}>
                      <CardActionArea>
                        <CardContent>
                          <Typography variant="body1">{item.item_name}</Typography>
                          <Typography variant="body2" color="textSecondary">Quantity: {item.item_qty}</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        ) : <Box flex={2} p={2} borderLeft="1px solid #ddd" ml={2} overflow="auto">
        </Box>
        }
      </Box>

      {/* Snackbar for error notifications */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">{error}</Alert>
      </Snackbar>

      <CRMCreateNewDocument open={showCreateDocument} onClose={() => setShowCreateDocument(false)} />
      <CRMWarehousesDialog open={showSelectWarehouse} onClose={handleSelectWarehouse} />
    </Box>
  );
}
