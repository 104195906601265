import axios from 'axios';

export function setupAxios() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://localhost:3001';
    console.log('Development mode Axios base URL:', axios.defaults.baseURL);
  } else {
    axios.defaults.baseURL = 'https://uploads.shortletsmalta.com';
    axios.interceptors.request.use((config) => {
      if (config.url && config.url.startsWith('/Office/Proj')) {
        config.baseURL = 'https://projects.api.leviathan.systems'; // Replace with the desired base URL
      }
      return config;
    }, (error) => {
      // Handle request error
      return Promise.reject(error);
    });
  }
}
