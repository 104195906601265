import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  MenuItem, Menu,
  TablePagination,
  TableRow, TableCell,
  Select, Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import Trash from '@mui/icons-material/Delete';
import axios from 'axios';
import { useSnackbar } from '../../../common/error_provider';
import { orangeColor, CommonSX } from '../../../css/common_sx';
import { TableGrid } from '../../../common/TableGrid';
import { searchFunction } from '../../../common/search_function';
import { debounce } from 'lodash';
import { useMenu } from '../../../common/menu_navigator';

export default function Workers() {
  const { showError } = useSnackbar();
  const [workers, setWorkers] = useState([]);
  const [loading, setloading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const fetchWorkers = async () => {
    setloading(true);
    axios.get('/Office/Projects/Workers')
      .then(response => {
        setWorkers(response.data);
        setloading(false);
      })
      .catch(error => {
        showError('Error fetching workers');
        setloading(false);
      });
  };

  useEffect(() => {
    fetchWorkers();
  }, []);


  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const filteredWorkers = useMemo(() => {
    return searchFunction(globalSearch, ['name'], workers)
  }, [globalSearch, workers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const columns = [
    'ID',
    'Name',
  ];

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%" pl={1} pr={1} pb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={1} borderRadius={2} boxShadow={3}
        bgcolor={orangeColor}>
        <TextField
          fullWidth
          label="Search"
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 1 }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />}
          onClick={() => { }} >
          Create New Worker
        </Button>

      </Box>
      <TableGrid columns={columns} loading={loading}>
        {filteredWorkers
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((task) => (
            <TableRow key={task.id}>
              <TableCell>{task.id}</TableCell>
              <TableCell>{task.worker_name}</TableCell>
              <TableCell>
                <Stack direction="row" spacing={2}>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[31, 50, 60]}
        component="div"
        sx={{ ...CommonSX, marginTop: '11px' }}
        count={workers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

