import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { orangeColor } from '../../../css/common_sx';
import axios from 'axios';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DialogSaveButton, DialogCancelButton } from '../../../common/DialogButtons';
import LoadingBackdrop from '../../../common/loading_backdrop';
export default function CRMCreateNewPartner({ open, onClose, onNew }) {
  const [formData, setFormData] = useState({});
  const [saving, setSaving] = useState(false);

  const handleFormDataUpdate = (key, value) => {
    setFormData({ ...formData, [key]: value });
  }

  const handleSave = () => {
    if (!formData.name || !formData.email || !formData.phone) {
      return;
    }
    setSaving(true);
    axios.post('/Office/CRM/Partners', {
      partner_name: formData.name,
      partner_email: formData.email,
      partner_phone: formData.phone
    })
      .then((response) => {
        if (response.status === 200) {
          onNew(response.data);
          axios.get(`/Office/CRM/Partners/${response.data.id}`)
            .then((response) => {
              setSaving(false);
              onNew(response.data);
              onClose();
            }).catch((err) => {

            });
        }
      }).catch((err) => {
        console.error(err);
        setSaving(false);
      });
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle style={{ backgroundColor: orangeColor, color: 'white' }}> Create New Partner </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                onChange={(e) => handleFormDataUpdate('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                onChange={(e) => handleFormDataUpdate('email', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                onChange={(e) => handleFormDataUpdate('phone', e.target.value)}
              />
            </Grid>
          </Grid>
          <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
          <Typography variant='h6'>Create New Appointment</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogSaveButton onClick={handleSave} />
        <DialogCancelButton onClick={onClose} />
      </ DialogActions>
      <LoadingBackdrop open={saving} text="Saving Partner..." />
    </Dialog>
  )
}
