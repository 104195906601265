import React, { useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { orange } from "@mui/material/colors";

const conversations = [
  { id: 1, name: "Alice", message: "Hey! How’s it going?", avatar: "https://i.pravatar.cc/40?img=1" },
  { id: 2, name: "Bob", message: "Are you available for a call?", avatar: "https://i.pravatar.cc/40?img=2" },
  { id: 3, name: "Charlie", message: "Let's meet tomorrow.", avatar: "https://i.pravatar.cc/40?img=3" }
];

export default function LeviathanChatDrawer({ open, onClose }) {
  const [selectedChat, setSelectedChat] = useState(conversations[0]);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSend = () => {
    if (inputMessage.trim()) {
      setMessages([...messages, { text: inputMessage, sender: "me" }]);
      setInputMessage("");
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ display: "flex", height: "100%", width: '30vw', backgroundColor: "#1A237E", color: "white", borderRadius: 2, boxShadow: 3 }}>
        {/* Conversations List */}
        <Box sx={{ width: 40, backgroundColor: "#111A5A", padding: 1 }}>
          <Typography sx={{ color: orange[500], fontSize: 12, fontWeight: "bold", textAlign: "center", mb: 1 }}>Chats</Typography>
          <List sx={{ p: 0 }}>
            {conversations.map((chat) => (
              <ListItem button key={chat.id} onClick={() => setSelectedChat(chat)} sx={{ justifyContent: 'center', mb: 1 }}>
                <Avatar src={chat.avatar} sx={{ width: 20, height: 20, border: `2px solid ${selectedChat.id === chat.id ? orange[500] : 'transparent'}` }} />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Chat Window */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", borderLeft: "1px solid #333" }}>
          <Box sx={{ padding: 1.5, backgroundColor: "#283593", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>{selectedChat.name}</Typography>
            {loading && <CircularProgress size={20} sx={{ color: orange[500] }} />}
          </Box>

          {/* Messages */}
          <Box sx={{ flex: 1, padding: 1.5, overflowY: "auto", display: 'flex', flexDirection: 'column', gap: 1 }}>
            {messages.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: msg.sender === "me" ? orange[500] : "#3949AB",
                  padding: 1,
                  borderRadius: 2,
                  maxWidth: "70%",
                  alignSelf: msg.sender === "me" ? "flex-end" : "flex-start",
                  boxShadow: 1
                }}
              >
                <Typography variant="body2">{msg.text}</Typography>
              </Box>
            ))}
          </Box>

          {/* Input Field */}
          <Divider />
          <Box sx={{ display: "flex", alignItems: "center", padding: 1, backgroundColor: "#1A237E" }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              sx={{ backgroundColor: "white", borderRadius: 2, mr: 1 }}
              placeholder="Type a message..."
            />
            <IconButton onClick={handleSend} sx={{ color: orange[500] }}>
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

