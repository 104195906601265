import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, List, ListItem, Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import CommonDialog from '../../Dialogs/CommonDialog';
import TitleBox from './Title';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import getDateString from './utils';

export function MostUsedProperties({ days, max, selectedProperty }) {
  const [reservations, setReservations] = useState([]);
  const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString());
  const [error, setError] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get(`/Office/reservations?type=5&date_to=${getDateString(new Date())}&date_from=${getDateString(new Date(), -days)}`);
      if (res.data && res.data.reservations) {
        const reservationCount = res.data.reservations.reduce((acc, reservation) => {
          acc[reservation.listing_property_id] = (acc[reservation.listing_property_id] || 0) + 1;
          return acc;
        }, {});

        const nightCount = res.data.reservations.reduce((acc, reservation) => {
          acc[reservation.listing_property_id] = (acc[reservation.listing_property_id] || 0) + reservation.no_of_nights;
          return acc;
        }, {});

        const sortedReservations = Object.entries(reservationCount)
          .sort((a, b) => b[1] - a[1])
          .slice(0, max)
          .map(([propertyId, count]) => {
            const totalNights = nightCount[propertyId] || 0;
            const avgNights = count > 0 ? (totalNights / count).toFixed(2) : 0;
            return {
              propertyId,
              count,
              avgNights,
              ...res.data.reservations.find(r => r.listing_property_id === propertyId)
            };
          });
        setReservations(sortedReservations);
        setError(false);
      } else {
        setError(true);
      }
      setRefreshTime(new Date().toLocaleTimeString());
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [days, max]);

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" mb={2}>
      <TitleBox title={`Top ${max} Booked Properties for the Last ${days} Days`} refreshTime={refreshTime} error={error} onRefresh={fetchData} />
      <Box
        width="100%"
        height={350}
        bgcolor="lightgoldenrodyellow"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {reservations.length > 0 ? (
          <Box position="relative" flex="1" width="100%" height="100%">
            <List
              sx={{
                top: '0', bottom: '0', left: '0', right: "0",
                position: "absolute", overflow: "auto"
              }}
            >
              {reservations.map((booking) => (
                <ListItem button key={`mu${booking.propertyId}`} onClick={() => { selectedProperty(booking.propertyId) }}>
                  <Avatar
                    sx={{ width: 40, height: 40, marginRight: 2 }}
                    src={booking.listing_image}
                  />
                  <ListItemText primary={booking.listing_property_name} secondary={`Reservations: ${booking.count}`} />
                  <Typography variant="body2" color="textSecondary">
                    {`Avg Nights: ${booking.avgNights}`}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        ) : (
          <Typography>No properties found.</Typography>
        )}
      </Box>
    </Box>
  );
}

export function PropertiesMostUsedDetail({ propertyid, visible, onclose, days, selectedReservation }) {
  const [reservations, setReservations] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(true);
  const fetchData = async () => {
    try {
      setloading(true);
      setReservations([]);
      const res = await axios.get(`/Office/reservations?type=5&date_to=${getDateString(new Date())}&date_from=${getDateString(new Date(), -days)}`);
      if (res.data && res.data.reservations) {
        const filtererddata = res.data.reservations
          .sort((a, b) => new Date(a.channel_create_date) - new Date(b.channel_create_date))
          .filter(data => data.listing_property_id == propertyid)
        setReservations(filtererddata);
        setError(false);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
    setloading(false);
  };
  const calculateSummary = () => {
    const totalBookings = reservations.length;
    const totalPrice = reservations.reduce((sum, booking) => sum + parseFloat(booking.guest_price), 0);
    const averageNights = totalBookings > 0 ? (reservations.reduce((sum, booking) => sum + booking.no_of_nights, 0) / totalBookings).toFixed(2) : 0;

    return {
      totalBookings,
      totalPrice,
      averageNights
    };
  };

  const { totalBookings, totalPrice, averageNights } = calculateSummary();

  useEffect(() => {
    if (visible) { fetchData(); }
  }, [visible]);
  return (
    <CommonDialog open={visible} onClose={() => onclose()} title={`Reservation for ${days} days`}>
      <Box position="relative" flex="1" width="100%" height="85%">
        {loading ?
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress
              size={24}
              sx={{
                color: 'green',
                position: 'absolute',
              }}
            /></Box>
          :
          <List
          >
            {reservations.map((booking) => (
              <ListItem button key={`mu${booking.propertyId}`} onClick={() => { selectedReservation(booking.reservation_id) }}>
                <Avatar
                  sx={{ width: 40, height: 40, marginRight: 2 }}
                  src={`https://pcrms.leviathan.systems${booking.ota_image}`}
                />
                <ListItemText primary={booking.client_name} secondary={`Nights: ${booking.no_of_nights}`} />
                <Typography variant="body2" color="textSecondary">
                  {`Price: ${booking.guest_price}`}
                </Typography>
              </ListItem>
            ))}
          </List>}

      </Box>
      <Box sx={{ padding: 2, borderTop: '1px solid #ccc' }} >
        <Typography variant="body1">Summary:</Typography>
        <Typography variant="body2">{`Total Bookings: ${totalBookings}`}</Typography>
        <Typography variant="body2">{`Total Price: $${totalPrice.toFixed(2)}`}</Typography>
        <Typography variant="body2">{`Average Nights: ${averageNights}`}</Typography>
      </Box>
    </CommonDialog>
  )
}


