import React, { useState, useEffect, useCallback, useMemo } from 'react';
import CommonDialog from '../../Dialogs/CommonDialog';
import { TextField, Box, TableRow, TableCell, TablePagination } from '@mui/material';
import { searchFunction } from '../../common/search_function';
import { TableGrid } from '../../common/TableGrid';
import debounce from 'lodash.debounce';
import axios from 'axios';

export default function PartnerPicker({ open, onClose, onSelect, defaultvalue }) {
  const [partners, setPartners] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const fetchPartners = async () => {
    setLoading(true);
    axios.get('/Office/Accounts/Partners')
      .then((response) => {
        setPartners(response.data);
        setLoading(false);
      })
      .catch((err) => { });
  };

  const filteredPartners = useMemo(() => {
    return searchFunction(globalSearch, ['partner_name', 'address', 'phone', 'email'], partners)
  }, [globalSearch, partners]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300),
    []
  );

  useEffect(() => {
    if (!open) return
    setGlobalSearch(defaultvalue ?? "");
    fetchPartners()
  }, [open])

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    }
  }, [handleSearchChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <CommonDialog open={open} onClose={onClose} title="Pick Partner" onSave={() => { }}>
      <Box display='flex' flexDirection='column'>
        <TextField
          fullWidth
          autoFocus={true}
          defaultValue={globalSearch}
          label='Search'
          onChange={(e) => handleSearchChange(e.target.value)}
        />
        <TableGrid columns={['Name', 'Address', 'Phone', 'Email']} loading={loading} enableOuterShell={false} rowsPerPage={rowsPerPage}>
          {filteredPartners
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((partner, index) => (
              <TableRow hover key={partner.id} onClick={() => onSelect(partner)}>
                <TableCell>{partner.partner_name}</TableCell>
              </TableRow>
            ))}
        </TableGrid>
        <TablePagination
          rowsPerPageOptions={[20, 50, 60]}
          component="div"
          sx={{ marginTop: "10px" }}
          count={partners.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Box>
    </CommonDialog>
  );
}
