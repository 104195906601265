import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import axios from 'axios';
import md5 from 'md5';
import myobj from './global';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from './common/error_provider';
import { orangeColor } from './css/common_sx';
import { DialogSaveButton, DialogCancelButton } from './common/DialogButtons';
import { setupAxios } from './common/setupaxios';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://leviathan.systems/">
        Leviathan Systems
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#D4AF37', // Gold color for primary actions
    },
    background: {
      default: '#0D1117', // Dark navy background
    },
    text: {
      primary: '#D4AF37', // Gold for text
    },
  },
});



function ChooseCompany({ visible, onChoose }) {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await axios.get('/StaffGetCompanies');
      setCompanies(response.data.list);
    };
    if (visible) {
      fetchCompanies();
    }
  }, [visible]);

  return (
    <Dialog open={visible} onClose={() => onChoose(false)} maxWidth="md" fullWidth>
      <DialogTitle bgcolor={orangeColor} color='white'>Choose Company</DialogTitle>
      <DialogContent dividers>
        <List>
          {companies.map((company) => (
            <ListItem button key={company.id} onClick={() => {
              axios.defaults.headers.common['company_id'] = company.id;
              onChoose(true);
            }}>
              <ListItemAvatar>
                <Avatar src={company.image} alt={company.name} />
              </ListItemAvatar>
              <ListItemText primary={company.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton ojClick={() => onChoose(false)} />
      </DialogActions>
    </Dialog>
  );
}

export default function SignInSide({ onLogin }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showInfo, showError } = useSnackbar();

  const handleCompanyChoose = (isChosen) => {
    setVisible(false);
    if (isChosen) {
      onLogin();
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    let auth = md5(''.concat(data.get('email'), ':1', md5(data.get('password'))));
    axios.get('/Office/Login', {
      headers: { LeviathanAuth: auth }
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.message === 'auth ok') {
          showInfo('Login successful');
          axios.defaults.headers.common['LeviathanAuth'] = auth;
          myobj.user.fullname = response.data.userfullname;
          myobj.user.obj_id = response.data.obj_id;
          setVisible(true);
          setLoading(false);
        } else {
          showError('Login failed');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        showError('Server error, please try again later');
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://picsum.photos/1920/1080)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/*
              <FormControlLabel
                control={<Checkbox value={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)} color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, position: 'relative' }}
                disabled={loading} // Disable the button when loading
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
                {loading ? 'Signing In...' : 'Sign In'} {/* Show different label based on loading state */}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ChooseCompany visible={visible} onChoose={handleCompanyChoose} />
    </ThemeProvider>
  );
}
