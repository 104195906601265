// SnackbarProvider.jsx

import React, { createContext, useContext, useState } from 'react';
import { Snackbar, Alert, Box } from '@mui/material';

// Create Context
const SnackbarContext = createContext(null);

// Custom Hook
export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

// Snackbar Provider Component
export const SnackbarProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  // Add a new message to the queue
  const showMessage = (message, severity = 'info') => {
    setMessages((prev) => [...prev, { id: Date.now(), message, severity, open: true }]);
  };

  const showError = (message) => showMessage(message, 'error');
  const showInfo = (message) => showMessage(message, 'info');
  const showSuccess = (message) => showMessage(message, 'success');
  const showWarning = (message) => showMessage(message, 'warning');

  // Close snackbar by ID
  const handleClose = (id) => {
    setMessages((prev) => prev.map((msg) => (msg.id === id ? { ...msg, open: false } : msg)));
    setTimeout(() => {
      setMessages((prev) => prev.filter((msg) => msg.id !== id));
    }, 300);
  };

  return (
    <SnackbarContext.Provider value={{ showError, showInfo, showSuccess, showWarning }}>
      {children}
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          display: 'flex',
          flexDirection: 'column-reverse', // Stack from bottom to top
          gap: 1, // Space between snackbars
          zIndex: 1300, // Ensure it's above other content
        }}
      >
        {messages.map((msg) => (
          <Snackbar
            key={msg.id}
            open={msg.open}
            autoHideDuration={6000}
            onClose={() => handleClose(msg.id)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={() => handleClose(msg.id)}
              severity={msg.severity}
              sx={{ width: '100%' }}
            >
              {msg.message}
            </Alert>
          </Snackbar>
        ))}
      </Box>
    </SnackbarContext.Provider>
  );
};

