import React, { useEffect, useState } from 'react';
import {
  Box,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  Checkbox,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { TableGrid } from '../../common/TableGrid';
import { orangeColor } from '../../css/common_sx';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Info from '@mui/icons-material/Info';

export default function MonitoringDCI({ visible, device }) {
  const [dcis, setDcis] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchDcis = async () => {
    setLoading(true);
  };

  useEffect(() => {
    if (!visible) return;
    fetchDcis();
  }, [visible, device]);

  const columns = [
    'ID'
  ]

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Box display="flex" flexDirection="column" width="100%" height="100%" flex={2}>
        <TableGrid columns={columns} loading={loading}>
        </TableGrid>
      </Box>
    </Box>
  );
}
