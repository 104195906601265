import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  MenuItem, Menu,
  TablePagination,
  TableRow, TableCell,
  Select, Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import Trash from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import { PunchClock } from '@mui/icons-material';
import { ArrowCircleRightOutlined, DeleteRounded } from '@mui/icons-material';
import axios from 'axios';
import { useSnackbar } from '../../../common/error_provider';
import { orangeColor, CommonSX } from '../../../css/common_sx';
import { TableGrid } from '../../../common/TableGrid';
import { searchFunction } from '../../../common/search_function';
import { debounce } from 'lodash';
import { useMenu } from '../../../common/menu_navigator';
import SelectFieldDialog from '../../../Dialogs/SelectFieldDialog';

export default function Workpackages() {
  const [projects, setProjects] = useState([]);
  const { showError } = useSnackbar();
  const { navigateWithId } = useMenu();
  const [selectedProject, setSelectedProject] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setloading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [workPackageTypes, setWorkPackageTypes] = useState([]);
  const [openSelectWorkProject, setOpenSelectWorkProject] = useState(false);
  const [selectedWorkProjectType, setSelectedWorkProjectType] = useState(null);
  useEffect(() => {
    // Fetch projects from endpoint
    axios.get('/Office/Projects/WorkPackageTypes')
      .then(response => {
        if (response.data.length > 0) {
          setWorkPackageTypes(response.data);
        }
      }).catch(error => {
        showError('Error fetching work package types');
      });
    axios.get('/Office/Projects')
      .then(response => {
        setProjects(response.data);
        if (response.data.length > 0) {
          setSelectedProject(response.data[0]);
        }
      })
      .catch(error => {
        showError('Error fetching projects');
      });
  }, []);

  useEffect(() => {
    if (selectedProject) {
      // Fetch tasks for selected project
      setloading(true);
      setTasks([]);
      axios.get(`/Office/Projects/WorkProjects/${selectedProject.id}`)
        .then(response => {
          if (response.data) {
            //Make Grouped By project_name
            setTasks(response.data);
          }
          setloading(false);
        })
        .catch(error => {
          showError(`Error fetching tasks for ` + selectedProject.project_name);
        });
    }
  }, [selectedProject]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNewItemClicked = (type) => {
    handleMenuClose();
    if (selectedProject?.id !== -1) {
      navigateWithId(6002, { new: true, project_id: selectedProject.id, type_id: type.id, type_name: type.name });
    } else {
      setOpenSelectWorkProject(true);
      setSelectedWorkProjectType(type);
    }
  };

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const filteredTasks = useMemo(() => {
    return searchFunction(globalSearch, ['package_name'], tasks)
  }, [globalSearch, tasks]).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteWorkPackage = (item) => {
    if (!item) return;
    axios.delete(`/Office/Projects/WorkProjects/${selectedProject.id}/${item.id}`)
      .then((res) => {
        if (res.status.toString() === '200') {
          setTasks(tasks.filter((task) => task.id !== item.id));
        }
      })
      .catch((err) => { });
  }

  const columns = [
    'ID',
    'Name',
    'Status',
    'Type',
    'Priority',
    'Assigned Persons',
    'Expected Finish Date',
    'Target Version',
    'Work hours',
    'Actions'
  ];

  const groupedTasks = () => {
    const lgroupedTasks = {};
    filteredTasks.forEach((task) => {
      const projectName = task.project_name;
      if (!lgroupedTasks[projectName]) {
        lgroupedTasks[projectName] = [];
      }
      lgroupedTasks[projectName].push(task);
    });
    return lgroupedTasks;
  };

  const renderTaskRow = (task) => (
    <TableRow key={task.id} sx={{ pl: 2 }}>
      <TableCell>{task.id}</TableCell>
      <TableCell>{task.package_name}</TableCell>
      <TableCell>{task.status_label}</TableCell>
      <TableCell sx={{ color: task.type_color || 'red' }}>{task.type_name}</TableCell>
      <TableCell>{task.priority_label}</TableCell>
      <TableCell></TableCell>
      <TableCell>{task.expected_finish_date ? new Date(task.expected_finish_date).toLocaleDateString() : ''}</TableCell>
      <TableCell>{task.version_label}</TableCell>
      <TableCell>{task.package_work_hours}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            onClick={() => navigateWithId(6002, { id: task.id, project_id: task.project_id })}
          >
            <InfoIcon fontSize='small' />
          </IconButton>
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            onClick={() => { handleDeleteWorkPackage(task) }}
          >
            <Trash fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            onClick={() => { }}
          >
            <PunchClock fontSize="small" />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={1} borderRadius={2} boxShadow={3}
        bgcolor={orangeColor}>
        <Select
          label="Project"
          value={selectedProject != null ? selectedProject.project_name ? selectedProject.id : '' : ''}
          onChange={(e) => setSelectedProject(projects.find(p => p.id === e.target.value))}
          sx={{ bgcolor: 'white', borderRadius: 1, }}>
          {projects.map(project => (
            <MenuItem key={project.id} value={project.id}>{project.project_name}</MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          label="Search"
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 1 }}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />}
          onClick={handleMenuOpen} >
          Create
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {workPackageTypes.map((type, index) => (
            <MenuItem key={index} onClick={() => handleNewItemClicked(type)} style={{ color: type.color }}>
              {(type.name || "N/A").toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <TableGrid columns={columns} loading={loading}>
        {selectedProject?.id === -1 ? (
          // Render grouped tasks
          Object.entries(groupedTasks()).map(([projectName, groupedTasks]) => (
            <React.Fragment key={projectName}>
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {projectName}
                  </Typography>
                </TableCell>
              </TableRow>
              {groupedTasks.map(renderTaskRow)}
            </React.Fragment>
          ))
        ) : (
          // Render filtered tasks
          filteredTasks.map(renderTaskRow)
        )}
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        component="div"
        sx={{ ...CommonSX, marginTop: '10px' }}
        count={tasks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <SelectFieldDialog
        open={openSelectWorkProject}
        onClose={() => { setOpenSelectWorkProject(false) }}
        onSave={(item) => {
          navigateWithId(6002, { new: true, project_id: item.id, type_id: selectedWorkProjectType.id, type_name: selectedWorkProjectType.name });
        }}
        in_items={projects}
        valueField="project_name"
        title="Select Work Package Type" />
    </Box>
  )
}
