import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Tabs, Tab, IconButton, CircularProgress, Button, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Drawer } from '@mui/material';
import { Settings, Person, ChildCare, Refresh } from '@mui/icons-material';
export default function ReservationPopUp({ reservationID, visible, onclose }) {
  const [formData, setFormData] = useState({
    clientName: '',
    email: '',
    phone: '',
    createdAt: new Date(),
  });

  const [stays, setStays] = useState([]);
  const [tasks, setTasks] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (reservationID) {
      setLoading(true);
      axios.get(`/Office/Reservation/${reservationID}`)
        .then(response => {
          const reservation = response.data.header[0];
          setFormData({
            clientName: `${reservation.reservation_client_name} ${reservation.reservation_client_surname}`,
            email: reservation.reservation_client_email,
            phone: reservation.reservation_client_phone,
            createdAt: new Date(reservation.reservation_created_at),
          });
          const staysData = response.data.stays.map(stay => ({
            startDate: new Date(stay.date_from),
            endDate: new Date(stay.date_to),
            internalRef: stay.internal_ref,
            channelReservationId: stay.channel_reservation_id,
            guestPrice: stay.guest_price,
            propertyId: stay.listing_property_id,
            noOfAdults: stay.no_of_adults,
            noOfTeens: stay.no_of_teens,
            noOfChilds: stay.no_of_childs,
            checkinTime: new Date(stay.date_checkin),
            checkoutTime: new Date(stay.date_checkout),
            otaImage: stay.ota_image,
            listingImg: stay.listing_img,
            id: stay.id // Add the stay ID here
          }));
          setStays(staysData);
          setLoading(false);
        })
        .catch(error => {
          console.error('There was an error fetching the reservation data!', error);
          setLoading(false);
        });
    }
  }, [reservationID]);

  useEffect(() => {
    if (tabIndex === 2) {
      stays.forEach(stay => {
        if (!tasks[stay.id]) {
          fetchTasks(stay.id);
        }
      });
    }
  }, [tabIndex]);

  const fetchTasks = (stayId) => {
    axios.get(`/Office/Reservation/StayTasks/${stayId}`)
      .then(response => {
        setTasks(prevTasks => ({
          ...prevTasks,
          [stayId]: response.data.tasks
        }));
      })
      .catch(error => {
        console.error(`There was an error fetching the tasks for stay ID ${stayId}!`, error);
      });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (name, date) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
  };

  const handleStayDateChange = (index, name, date) => {
    setStays((prevStays) => {
      const newStays = [...prevStays];
      newStays[index] = {
        ...newStays[index],
        [name]: date,
      };
      return newStays;
    });
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  const ReservationStays = () => (

    stays.map((stay, index) => (
      <Box key={index} display="flex" flexDirection="column" mt={2} p={2} border={1} borderRadius={4} borderColor="grey.300">
        <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection={isMobile ? 'column' : 'row'}>
          <img src={`https://pcrms.leviathan.systems/${stay.otaImage}`} alt="OTA" style={{ height: 20, marginBottom: isMobile ? 10 : 0 }} />
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: isMobile ? 10 : 0 }}>
            Property ID: {stay.propertyId}
          </Typography>
          <IconButton>
            <Settings />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2} flexDirection={isMobile ? 'column' : 'row'}>
          <Box display="flex" flexDirection="column" alignItems={isMobile ? 'center' : 'start'}>
            <Typography variant="body1">Start Date</Typography>
            <Typography variant="body2">{stay.startDate.toLocaleString()}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems={isMobile ? 'center' : 'start'}>
            <Typography variant="body1">End Date</Typography>
            <Typography variant="body2">{stay.endDate.toLocaleString()}</Typography>
          </Box>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'}>
          <Typography variant="body2"><strong>Internal Ref:</strong> {stay.internalRef}</Typography>
          <Typography variant="body2"><strong>Channel Reservation ID:</strong> {stay.channelReservationId}</Typography>
          <Typography variant="body2"><strong>Guest Price:</strong> {stay.guestPrice}</Typography>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'}>
          <Box display="flex" alignItems="center">
            <Person /><Typography variant="body2" ml={1}>{stay.noOfAdults} Adults</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Person /><Typography variant="body2" ml={1}>{stay.noOfTeens} Teens</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <ChildCare /><Typography variant="body2" ml={1}>{stay.noOfChilds} Children</Typography>
          </Box>
          <Typography variant="body2"><strong>Total Guests:</strong> {stay.noOfAdults + stay.noOfTeens + stay.noOfChilds}</Typography>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'}>
          <Typography variant="body2"><strong>Check-in Time:</strong> {stay.checkinTime.toLocaleTimeString()}</Typography>
          <Typography variant="body2"><strong>Check-out Time:</strong> {stay.checkoutTime.toLocaleTimeString()}</Typography>
        </Box>
      </Box>
    ))
  );

  const StayTasks = () => (
    stays.map((stay) => (
      <Box key={stay.id} mt={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6">Tasks for Stay {stay.startDate.toLocaleString()} {stay.propertyId}</Typography>
          <IconButton onClick={() => fetchTasks(stay.id)}>
            <Refresh />
          </IconButton>
        </Box>
        {tasks[stay.id] ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Task Name</TableCell>
                  <TableCell>Issued At</TableCell>
                  <TableCell>Executed At</TableCell>
                  <TableCell>Requested At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks[stay.id].map(task => (
                  <TableRow key={task.id}>
                    <TableCell>{task.name}</TableCell>
                    <TableCell>{task.issued_at ? new Date(task.issued_at).toLocaleString() : 'N/A'}</TableCell>
                    <TableCell>{task.executed_at ? new Date(task.executed_at).toLocaleString() : 'N/A'}</TableCell>
                    <TableCell>{task.requested_at ? new Date(task.requested_at).toLocaleString() : 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2">No tasks found. Click the refresh icon to fetch tasks.</Typography>
        )}
      </Box>
    ))
  );

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  return (
    <Drawer
      open={visible}
      anchor="right"
      onClose={onclose}
    >
      <Box width={'40vw'} p={1}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="General" />
          <Tab label="Checklist" />
          <Tab label="Stay Tasks" />
        </Tabs>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
            <CircularProgress />
          </Box>
        ) : (
          <>
            {tabIndex === 0 && (
              <Box display="flex" flexDirection="column" gap={2} mt={2}>
                <TextField
                  label="Client Name"
                  name="clientName"
                  value={formData.clientName}
                  onChange={handleFormChange}
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleFormChange}
                />
                <TextField
                  label="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleFormChange}
                />
                <Box display="flex" flexDirection="column" alignItems="start">
                  <Typography variant="body1">Created At</Typography>

                </Box>
                <ReservationStays />
              </Box>
            )}
            {tabIndex === 1 && (
              <Box mt={2}>
                <Typography variant="body1">Checklist content here</Typography>
              </Box>
            )}
            {tabIndex === 2 && (
              <Box mt={2}>
                <StayTasks />
              </Box>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
}

