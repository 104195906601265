import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Grid,
  DialogActions,
} from '@mui/material';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { orangeColor } from '../../css/common_sx';
import PropertiesPhysicalTable from '../properties/physical_table_box';
import DocumentEditorDialog from './warehouse_documet_allowers';
import { searchFunction } from '../../common/search_function';
import { TableGrid } from '../../common/TableGrid';
import { CommonSX } from '../../css/common_sx';

function CRMCreateWarehouse({ open, onClose, onNew, alreadyExists }) {
  const [selectedOption, setSelectedOption] = useState('create');
  const [formData, setFormData] = useState({});

  const handleSave = () => {
    switch (selectedOption) {
      case 'create': { }
      case 'copy': {
        axios.post(`/Office/CRM/Warehouses/from_pms/${formData.id}`)
          .then((response) => {
            if (response.status === 200) {
              axios.get(`/Office/CRM/Warehouses/${response.data.id}`)
                .then((response2) => {
                  if (response2.status === 200) {
                    onNew(response2.data);
                    onClose();
                  }
                })
                .catch((error2) => { });
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              alreadyExists();
              onClose();
            }
          });

      }
      case 'person': {

      }
    }
  };

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: orangeColor, color: 'white' }}>
        Create New Warehouse
      </DialogTitle>
      <DialogContent>
        {/* Toggle Buttons to choose between different operations */}
        <Box sx={{ my: 3, textAlign: 'center' }}>
          <ToggleButtonGroup
            color="primary"
            value={selectedOption}
            exclusive
            onChange={handleOptionChange}
            aria-label="Warehouse operations"
          >
            <ToggleButton value="create" aria-label="create">
              Create New Property
            </ToggleButton>
            <ToggleButton value="copy" aria-label="copy">
              Copy from PMS System
            </ToggleButton>
            <ToggleButton value="person" aria-label="person">
              Make it a Person
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Content for 'Create New Property' */}
        {selectedOption === 'create' && (
          <Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Property Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="House Name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Street"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Postal Code"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button variant="contained" color="primary" size="large">
                Submit
              </Button>
            </Box>
          </Box>
        )}

        {/* Content for 'Copy from PMS System' */}
        {selectedOption === 'copy' && (
          <Box>
            <PropertiesPhysicalTable onSelect={(item) => setFormData(item)} />
          </Box>
        )}

        {/* Content for 'Make it a Person' */}
        {selectedOption === 'person' && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Make it a Person
            </Typography>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Example data, replace with actual data */}
                  <TableRow>
                    <TableCell>John</TableCell>
                    <TableCell>Doe</TableCell>
                    <TableCell>john.doe@example.com</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Jane</TableCell>
                    <TableCell>Smith</TableCell>
                    <TableCell>jane.smith@example.com</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button variant="contained" color="primary" size="large">
                Select Person
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}
          sx={{ color: orangeColor, borderColor: orangeColor, borderRadius: 2 }}
          variant="outlined">Cancel</Button>
        <Button onClick={handleSave} variant="contained"
          color="primary"
          sx={{ marginRight: 2, bgcolor: orangeColor, color: '#fff', borderRadius: 2 }}>Save</Button>

      </DialogActions>
    </Dialog>
  );
}

export default function CRMWarehouses() {
  const [globalFilter, setGlobalFilter] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [createWarehouseDialog, setCreateWarehouseDialog] = useState(false);
  const [typeAccesses, setTypeAccesses] = useState(false);

  useEffect(() => {
    axios.get('/Office/CRM/Warehouses')
      .then((response) => {
        setWarehouses(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Failed to fetch data');
        setLoading(false);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredWarehouses = searchFunction(globalFilter, ['name', 'full_address', 'capacity', 'current_storage'], warehouses);

  const groupedWarehouses = filteredWarehouses.reduce((acc, warehouse) => {
    if (!acc[warehouse.owner_name]) {
      acc[warehouse.owner_name] = [];
    }
    acc[warehouse.owner_name].push(warehouse);
    return acc;
  }, {});

  const columns = [
    'ID',
    'Name',
    'Address',
    'Capacity',
    'Used Capacity',
    'Actions'
  ]

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        bgcolor={orangeColor}  // Make sure `orangeColor` is defined as a valid color
        padding={2}  // Add padding for better spacing inside the box
        borderRadius={2}  // Optional: Adds a rounded border for a more modern look
        boxShadow={3}  // Optional: Adds a subtle shadow for depth
      >
        <TextField
          label="Search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          fullWidth
          sx={{
            bgcolor: 'white',  // White background to contrast with the box color
            borderRadius: 1,  // Adds a slight rounding to the TextField
            marginRight: 2,  // Space between the TextField and Button
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={(res) => {
            setCreateWarehouseDialog(true);
            if (res) {
              setWarehouses([...warehouses, res]);
            }
          }}
          sx={{
            textTransform: 'none',  // Keeps the text in normal casing
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          Add Item
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setTypeAccesses(true)}
          sx={{
            textTransform: 'none',  // Keeps the text in normal casing
            paddingLeft: 2,
            paddingRight: 2,
            marginLeft: 2,  // Space between the TextField
          }}
        >
          Warehouse Allows
        </Button>
      </Box>
      <TableGrid columns={columns} rowsPerPage={rowsPerPage} loading={loading} >
        {
          Object.keys(groupedWarehouses)
            //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(owner_name => (
              <React.Fragment key={owner_name}>
                <TableRow>
                  <TableCell colSpan={6} style={{ backgroundColor: '#f0f0f0' }}>
                    <Typography variant="subtitle1">{owner_name}</Typography>
                  </TableCell>
                </TableRow>
                {groupedWarehouses[owner_name].map((warehouse) => (
                  <TableRow key={warehouse.id}>
                    <TableCell>{warehouse.id}</TableCell>
                    <TableCell>{warehouse.name}</TableCell>
                    <TableCell>{warehouse.full_address || 'N/A'}</TableCell>
                    <TableCell>{warehouse.capacity || 'N/A'}</TableCell>
                    <TableCell>
                      {warehouse.current_storage ? (
                        <Box display="flex" alignItems="center">
                          <Box width="100%" mr={1}>
                            <LinearProgress
                              variant="determinate"
                              value={(warehouse.current_storage / warehouse.capacity) * 100}
                            />
                          </Box>
                          <Typography variant="body2" color="textSecondary">{`${Math.round(
                            (warehouse.current_storage / warehouse.capacity) * 100
                          )}%`}</Typography>
                        </Box>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))
        }
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        sx={{ ...CommonSX, marginTop: '10px' }}
        component="div"
        count={warehouses.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
      />
      <CRMCreateWarehouse
        open={createWarehouseDialog}
        onClose={() => setCreateWarehouseDialog(false)}
        onNew={(item) => {
          setWarehouses([...warehouses, item]);
        }}
        alreadyExists={() => {
          alert('Warehouse already exists');
        }}
      />

      <DocumentEditorDialog open={typeAccesses} onClose={() => setTypeAccesses(false)} />
    </Box>
  );
}

