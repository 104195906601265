import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Avatar,
  IconButton,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CommonDialog from "../../Dialogs/CommonDialog";
const EditProfileDialog = ({ open, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    photo: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, photo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  return (
    <CommonDialog open={open} onClose={onClose} title="Edit Profile">
      <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
        <Avatar src={formData.photo} sx={{ width: 80, height: 80 }} />
        <input
          accept="image/*"
          type="file"
          id="photo-upload"
          style={{ display: "none" }}
          onChange={handlePhotoUpload}
        />
        <label htmlFor="photo-upload">
          <IconButton component="span">
            <PhotoCameraIcon />
          </IconButton>
        </label>
      </div>
      <TextField
        fullWidth
        margin="dense"
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="dense"
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="dense"
        label="Phone"
        name="phone"
        type="tel"
        value={formData.phone}
        onChange={handleChange}
      />
    </CommonDialog>
  );
};

export default EditProfileDialog;

