import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, IconButton, TablePagination, Snackbar, Alert, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { searchFunction } from '../../../common/search_function';
import { debounce } from 'lodash';
import { orangeColor, CommonSX } from '../../../css/common_sx';

export default function PickPhysicalItemDialog({ open, onClose, itemID, srcWarehouse, onSelect }) {
  const [items, setItems] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchItems = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await axios.get(`/Office/CRM/PhysicalItems?src_warehouse=${srcWarehouse}`);
      const data = response.data;
      setItems(data);
    } catch (error) {
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    if (open) {
      fetchItems();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ bgcolor: orangeColor }}> Pick Physical Item </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SUN</TableCell>
                <TableCell>Item Name</TableCell>
                <TableCell>Current Place</TableCell>
                <TableCell>Last Place</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => {
                return (
                  <TableRow hover onClick={() => {
                    if (onSelect) {
                      onSelect(item);
                    }
                  }} key={item.physical_item_id}>
                    <TableCell>{typeof item.sun_values == 'object' ? Object.values(item.sun_values)[0] : ''}</TableCell>
                    <TableCell>{item.item_name}</TableCell>
                    <TableCell>{item.current_place_name}</TableCell>
                    <TableCell>{item.last_place_name}</TableCell>
                  </TableRow>);
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ color: orangeColor, borderColor: orangeColor, borderRadius: 2 }}
          onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>)
} 
