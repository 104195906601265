import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  MenuItem, Menu,
  TablePagination,
  TableRow, TableCell,
  Select, Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import GitHubIcon from '@mui/icons-material/GitHub';
import axios from 'axios';
import { useSnackbar } from '../../../common/error_provider';
import { orangeColor, CommonSX } from '../../../css/common_sx';
import { TableGrid } from '../../../common/TableGrid';
import { searchFunction } from '../../../common/search_function';
import { debounce } from 'lodash';
import { useMenu } from '../../../common/menu_navigator';
import SelectFieldDialog from '../../../Dialogs/SelectFieldDialog';

export default function ManageProjects() {
  const [projects, setprojects] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [globalSearch, setGlobalSearch] = useState('');

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const filteredProjects = useMemo(() => {
    return searchFunction(globalSearch, ['package_name'], projects);
  }, [globalSearch, projects]).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    axios.get('/Office/Projects')
      .then(response => {
        setprojects(response.data);
      })
      .catch(error => { });

  }
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    'ID',
    'Project Name',
    'Created At',
    'Created By',
    'Status',
    'Actions'
  ]

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={1} borderRadius={2} boxShadow={3}
        bgcolor={orangeColor}>
        <TextField
          fullWidth
          label="Search"
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 1 }}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </Box>
      <TableGrid loading={false} columns={columns} rowsPerPage={10}>
        {
          filteredProjects.map((project) => (
            <TableRow key={project.id}>
              <TableCell>{project.id}</TableCell>
              <TableCell>{project.project_name}</TableCell>
              <TableCell>{project.created_at}</TableCell>
              <TableCell>{project.created_by}</TableCell>
              <TableCell>{project.status}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))
        }
      </TableGrid>
    </Box>
  )
}

